.servicos-textos {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  width: 20%;
  height: 50%;
  font-family: "Grenze";
  margin-top: 10rem;
  font-size: 1.5rem;
  padding-left: 1em;
}

.servicos-titulo {
  font-family: "Grenze Gotisch";
  font-weight: normal;
  font-size: 2.25rem;
  margin-bottom: 1.5em;
}

.servicos-descricao {
  margin: 0;
  margin-bottom: 1rem;
}

@media (max-resolution: 1.25dppx) {
  .servicos-textos {
    margin-top: 8rem;
    font-size: 1.3rem;
    padding-left: 0em;
  }

  .servicos-titulo {
    margin: 0;
    padding-bottom: 2rem;
  }

}

@media screen and (max-width: 428px) {
  .servicos-textos * {
    margin: 0%;
    padding: 0%;
  }

  .servicos-textos {
    box-sizing: border-box;
    width: 75vw;
    height: fit-content;
    padding: 0%;
    margin: 0%;
  }
}
