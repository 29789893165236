.App {
  background-color: #0d0d0d;
}

.feedback > div {
  max-width: 100%;
  margin: 0 auto;
  height: 100%;
}

.feedback-titulo {
  font-family: "Grenze Gotisch";
  font-size: 4rem;
  font-weight: normal;
  width: fit-content;
  margin: 1em auto;
  margin-bottom: 2em;
}

.feedback-imagem {
  max-width: 100%;
  max-height: 100%;
}

.container-botoes {
  display: flex;
  box-sizing: border-box;
  width: 100%;
  height: 50px;
}

.botoes-swiper {
  height: 50px;
  width: 100%;
  display: flex;
  margin: 2rem 15rem 2rem 15rem;
  align-items: center;
  justify-content: space-evenly;
}

.swiper-button-prev,
.swiper-button-next {
  top: unset !important;
  bottom: unset !important;
  left: unset !important;
  right: unset !important;
  width: auto !important;
  position: unset !important;
  color: transparent !important;
}

.swiper-slide {
  margin-left: 1rem;
}

@media (min-resolution: 1.25dppx) {
  .feedback-titulo {
    font-size: 3rem;
    margin-bottom: 3rem;
  }

  .botoes-swiper {
    margin: 2rem 15rem 2rem 17rem;
    align-items: center;
    justify-content: space-evenly;
  }

  .swiper-slide {
    margin-left: 0;
  }
}

@media screen and (max-width: 428px) {
  .feedback-titulo {
    margin-top: 0;
    font-size: 1.25rem;
    margin-bottom: 3rem;
    text-align: center;
  }
  .container-botoes {
    justify-content: center;
    padding-left: 0.5rem;
  }
  .botoes-swiper {
    margin: 0rem;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 7rem;
  }

  .swiper-button-prev {
    height: 1.5rem;
    margin-left: 3rem;
  }
  .swiper-button-next {
    height: 1.5rem;
    margin-left: auto;
    margin-right: 3rem;
  }
}
