.contato {
  display: flex;
  background-image: url("/public/imagens/background_contato.png");
  background-position: center;
  background-size: cover;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  column-gap: 10rem;
  height: 110vh;
  padding: 0 5rem;
}

.logo-djn {
  width: 27rem;
  height: 27rem;
}

.contato h3 {
  color: white;
  font-family: "Grenze Gotisch";
  margin-top: 5rem;
  margin-bottom: 0;
  text-align: center;
  width: 100%;
  line-height: 5rem;
  font-size: 4rem;
}

.contato-direita {
  display: flex;
  flex-wrap: wrap;
  justify-content: right;
  padding-bottom: 5rem;
}

.contato-direita p {
  color: white;
  font-family: "Grenze Gotisch";
  margin-top: 0;
  text-align: right;
  line-height: 5rem;
  width: 100%;
  font-size: 4rem;
}

.img-insta {
  margin-right: 2rem;
}

.img-whatsapp {
  width: 100%;
  height: 100%;
  margin-left: 5rem;
  object-fit: contain;
}

.contato button {
  padding: 0;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

@media (min-resolution: 1.25dppx) {
  .contato {
    position: relative;
    justify-content: space-evenly;
  }
  .contato h3 {
    font-size: 3rem;
  }

  .logo-djn {
    width: 15rem;
    height: 15rem;
  }

  .link-whatsapp {
    width: 11.3rem;
    height: 18rem;
  }

  .contato-direita {
    font-size: 3rem;
    padding: 0;
  }
  .contato-direita p {
    font-size: 3rem;
    padding: 0;
  }
}

@media screen and (max-width: 428px) {
  .contato > h3 {
    margin: 0;
    padding-top: 2rem;
    font-size: 1.25rem;
    font-weight: normal;
    line-height: normal;
  }

  .contato p {
    font-size: 1.25rem;
    line-height: normal;
    text-align: center;
  }

  .contato {
    background-image: unset;
    justify-content: center;
    height: 60vh;
  }

  .container-logo {
    position: absolute;
    height: 62px;
    width: 62px;
    left: 0;
    bottom: 0;
    margin: 1rem;
  }

  .logo-djn {
    height: 100%;
    width: 100%;
    left: 0;
    bottom: 0;
  }

  .img-whatsapp {
    margin: 0;
  }

  .link-whatsapp {
    margin: 0;
    position: relative;
    width: 5rem;
    height: 7.6rem;
  }

  .link-insta,
  .link-discord {
    width: 3.2rem;
    height: 3.2rem;
  }

  .link-insta {
    margin-right: auto;
  }

  .img-insta,
  .img-discord {
    width: 100%;
    margin: 0;
  }
}
