.sobre {
  background-color: #0d0d0d;
  background-image: url("/public/imagens/background_sobre.png");
  background-position: center;
  background-size: cover;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
  font-family: "Grenze";
  padding: 1rem 5rem;
}

.sobre > * {
  margin: 0;
}

.sobre-titulo {
  font-family: "Grenze Gotisch";
  font-size: 4rem;
}

.sobre-descricao {
  font-size: 2.5rem;
  margin: 2.5rem 0rem 2.5rem 0rem;
}

.sobre h4 {
  font-size: 3rem;
  font-weight: normal;
}

@media (min-resolution: 1.25dppx) {
  .sobre-titulo {
    font-size: 3rem;
  }
  .sobre-descricao {
    font-size: 1.9rem;
    margin: 2.5rem 0rem 2.5rem 0rem;
  }

  .sobre h4 {
    font-size: 2.25rem;
    font-weight: normal;
  }
}

@media screen and (max-width: 428px) {
  .sobre {
    background-image: url("/public/imagens/background_sobre_428.png");
    background-size: contain;
    background-repeat: no-repeat;
    padding: 0;
    padding-left: 1.5rem;
    margin: 0;
    height: 45vh;
  }

  .sobre-titulo {
    font-size: 1.25rem;
  }

  .sobre-descricao {
    font-size: 0.9rem;
    margin: 2.5rem 0rem 2.5rem 0rem;
  }

  .sobre h4 {
    font-size: 1.25rem;
    font-weight: normal;
  }
}
