footer {
    position: relative;
    width: 100%;
    display: flex;
    font-size: 1rem ;
    justify-content: center;
    font-family: 'Grenze';
    background-color: transparent;
    color: white;
    top: 0%;
}

