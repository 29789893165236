.botao-orcamento {
  border: none;
  background-image: url("/public/imagens/botao_orcamento.svg");
  background-color: transparent;
  background-repeat: no-repeat;
  width: 39rem;
  height: 6.7rem;
  cursor: pointer;
  font-family: "Grenze Gotisch";
  font-size: 2.25rem;
  justify-content: center;
}

@media (min-resolution: 1.25dppx) {
  .botao-orcamento {
    font-size: 1.7rem;
    background-size: contain;
    text-align: center;
    width: 29.25rem;
    height: 5rem;
  }
}

@media screen and (max-width: 428px) {
  .botao-orcamento {
    font-size: 1.25rem; 
    width: 19.2rem;
    height: 3.2rem;
  }
}
