.cabecalho {
  background-image: url("/public/imagens/background_banner.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  color: white;
  font-family: "Grenze";
  height: 80vh;
  width: 100%;
}

span {
  color: #e6bb6e;
}

.cabecalho-logo {
  position: absolute;
  left: 90%;
  margin-top: 1.5em;
}

.cabecalho .texto__esquerda {
  position: absolute;
  font-size: 5rem;
  text-align: center;
  line-height: 1em;
  left: 5%;
  top: 0.5em;
  font-family: "Grenze Gotisch";
}

.cabecalho .texto__direita {
  position: absolute;
  text-align: right;
  right: 0.5em;
  top: 5em;
  font-size: 3rem;
  line-height: 1em;
  padding-right: 1.5em;
}

@media (min-resolution: 1.25dppx) {
  .cabecalho .texto__esquerda {
    font-size: 3.75rem;
    right: 66%;
  }

  .cabecalho .texto__direita {
    top: 3.7em;
    font-size: 2.75rem;
  }
}

@media screen and (max-width: 428px) {
  .cabecalho {
    padding-top: 7rem;
    background-image: url("/public/imagens/background_banner_428.png");
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: unset;
    height: 80vh;
    width: 100vw;
    color: white;
    font-family: "Grenze";
  }

  .cabecalho-logo {
    right: 0;
    top: 0%;
    margin: 1rem 1rem 0 auto;
    width: 4.7rem;
    height: 4.7rem;
  }

  .cabecalho-container__esquerda {
    display: flex;
    justify-content: center;
  }

  .cabecalho .texto__esquerda {
    text-align: center;
    font-size: 2.5rem;
    line-height: 1em;
    font-family: "Grenze Gotisch";
    margin: 0;
    left: 25%;
    top: 5%;
    width: 50%;
  }

  .cabecalho-container__direita {
    display: flex;
    justify-content: center;
    align-self: center;
  }

  .cabecalho .texto__direita {
    position: relative;
    margin: 0;
    right: 0;
    top: 850% !important;
    font-size: 1.75rem;
    line-height: 1em;
    padding-right: 0em;
  }
}
