.App {
  background-color: #0d0d0d;
}

.feedback {
  height: 100vh;
  background-size: cover;
  background-color: #0d0d0d;
  color: white;
  width: 100%;
}

@media screen and (max-width: 428px) {
  .feedback {
    background-image: url("/public/imagens/background_feedback.png");
    background-size: cover;
    background-position: center;
    background-color: #0d0d0d;
    padding-top: 2.5rem;
    height: 60vh;
  }
}
