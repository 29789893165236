.servicos {
  position: relative;
  background-image: url("/public/imagens/background_servicos.png");
  background-position: bottom;
  background-color: #0d0d0d;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  box-sizing: border-box;
  justify-content: space-evenly;
  align-items: center;
  column-gap: 5rem;
  row-gap: 10em;
  width: 100%;
  height: 100vh;
  flex-wrap: wrap;
}

@media (min-resolution: 1.25dppx) {
  .servicos {
    padding-left: 1rem;
    row-gap: 9em;
  }
}

@media screen and (max-width: 428px) {
  .servicos {
    background-image: url("/public/imagens/background_servicos_428.png");
    justify-content: center;
    padding: 0;
    row-gap: 0em;
    height: 80vh;
  }
}
